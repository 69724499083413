module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RT97GJRGBY"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Basic Starter","short_name":"Starter","description":"Gatsby blog site starter-kit with Contentful","start_url":"/","lang":"ja","background_color":"#fff","theme_color":"#222","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | Basic Starter","description":"That's one small step for a man, one giant leap for mankind.","language":"ja","openGraph":{"title":"Gatsby Contentful Basic Starter","description":"A basic kit with everything you need to get started your blog with Gatsby and Contentful.","images":[{"url":"https://images.ctfassets.net/96mljui2ibo8/5wmtbyG3oakEGiOZpk2Pxh/025688f621e2baa443206eafb4c3af50/og.png","width":800,"height":600,"alt":"Og Image Alt"}],"type":"website","locale":"ja_JP","url":"https://gatsby-contentful-basic-starter.pages.dev","site_name":"Basic Starter"},"twitter":{"site":"@tanden0914","handle":"@tanden0914","cardType":"summary_large_image"},"facebook":{"appId":"your-app-id"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"gold","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
